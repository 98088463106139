import React from 'react';

import Scroll from '../components/Scroll';
import { Link } from 'gatsby';

import small_icon from '../assets/images/small_icon.png';

export default function Menubar(props) {
  return (
    <menu id="menu" className="wrapper">
      <div className="inner menu-wrapper">
        <Scroll type="brand" element="header">
          <a id="brand" href='#header'>
            <img id="menu-logo" src={small_icon} alt="Logo" /><span id="menu-name">Cleave</span>
          </a>
        </Scroll>
        <ul>
            {props.scrollLinks.map(scrollLink => {
                const { name, id } = scrollLink;
                return (
                  <li key={id}>
                    <Scroll type="id" element={id}>
                      <a href={'#'+id}>
                        <span className="label">{name}</span>
                      </a>
                    </Scroll>
                  </li>
                )
            })}
            <li className="separator" key="separator"></li>
            {props.menuLinks.map(menuLink => {
              const { name, link } = menuLink;
              return (
                <li key={link}>
                  <Link to={link}>
                    <span className="label">{name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
    </menu>
  );
}
