module.exports = {
  siteTitle: 'Cleave (Coming soon) - Enabling human context switching', // <title>
  menuLinks: [
    {
       name: 'Home',
       link: '/'
    },
    {
       name: 'Elements',
       link: '/Elements'
    }
  ],
  manifestName: 'Fractal',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  //pathPrefix: `/gatsby-starter-fractal/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Cleave',
  subHeading: 'Enabling human context switching',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/eivindarvesen/cleave-issues',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/CleaveApp',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/CleaveApp',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:contact@cleave.app',
    },
  ]
};
